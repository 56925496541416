import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";

function Payment() {
  const [state, setState] = useState("");

  return (
    <>
      <Container className="my-5">
        <Row>
          <Col lg={6} md={10} sm={10} className="mx-auto">
            <div className="iframeForm">
              <iframe
                src="https://donorbox.org/embed/test-campaign-645"
                name="donorbox"
                allowpaymentrequest="allowpaymentrequest"
                seamless="seamless"
                frameborder="0"
                scrolling="no"
                height="900px"
                width="100%"
                style={{
                  maxWidth: "500px",
                  minWidth: "250px",
                  maxHeight: "none",
                }}
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Payment;
