import React, { useRef } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Col, Container, Modal, ModalBody, Row } from "reactstrap";
import BoyLogo from "../assets/img/ManPicture.png";
import GirlLogo from "../assets/img/girlAdvertisement.png";
import GroupImg from "../assets/img/GroupImage.png";
import Because from "../assets/img/Because.png";
import ArrowLeft from "../assets/img/ArrowLeft.png";
import ArrowRight from "../assets/img/ArrowRight.png";
import HereIsHow from "../assets/video/HERE HOW.mp4";
import HereIsWhy from "../assets/video/HERE WHY.mp4";
function NewHome() {
  const videoRef = useRef(null);
  const [hereIsHowDemoVideo, setHereIsHowDemoVideo] = useState(false);
  const [hereIsWhyDemoVideo, setHereIsWhyDemoVideo] = useState(false);

  const handleClickHereIsHow = () => {
    setHereIsHowDemoVideo(true);
    videoRef.current.play();
  };
  const hereIsHowToggle = () => {
    setHereIsHowDemoVideo(!hereIsHowDemoVideo);
  };
  const handleClickHereIsWhy = () => {
    setHereIsWhyDemoVideo(true);
  };
  const hereIsWhyToggle = () => {
    setHereIsWhyDemoVideo(!hereIsWhyDemoVideo);
  };
  return (
    <>
      <Helmet>
        <title>
          UNKLE501 | Donations and Philanthropy in the US | United States
          donation rule
        </title>
        <meta
          name="description"
          content="UNKLE501 in the United States governs the tax-exempt status of certain organizations and their eligibility to receive tax-deductible contributions."
        />
        <meta
          property="og:title"
          content="UNKLE501 | United States donation rule"
        />
        <meta
          property="og:description"
          content="UNKLE501 is a donation rule in the United States that governs the tax-exempt status of certain organizations and their eligibility to receive tax-deductible contributions."
        />
        <meta
          property="keywords"
          content="UNKLE501, UNKLE501 rule, US donation rule, Charitable contributions in the US, Tax-deductible donations, Non-profit organizations in the US, IRS donation rules, 501(c)(3) organizations, Tax-exempt donations, US philanthropy rules"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://unkle501.com/" />
      </Helmet>
      {/* <video ref={videoRef}>
				<source src={HereIsHow} type='video/mp4' />
			</video> */}
      <Modal isOpen={hereIsWhyDemoVideo} toggle={hereIsWhyToggle} centered>
        <ModalBody className="bg-secondary">
          <div className="float-right">
            <i
              onClick={hereIsWhyToggle}
              className="fas fa-times searchIcon"
            ></i>
          </div>
          <div className="text-center">
            <video
              style={{ width: "400px", height: "400px" }}
              src={HereIsWhy}
              controls
            ></video>
          </div>
          {/* </video> */}
        </ModalBody>
      </Modal>

      <Modal isOpen={hereIsHowDemoVideo} toggle={hereIsHowToggle} centered>
        <ModalBody className="bg-secondary">
          <div className="float-right">
            <i
              onClick={hereIsHowToggle}
              className="fas fa-times searchIcon"
            ></i>
          </div>
          <div className="text-center">
            <video
              style={{ width: "400px", height: "400px" }}
              src={HereIsHow}
              controls
            ></video>
          </div>
          {/* </video> */}
        </ModalBody>
      </Modal>
      <Container className="my-5 text-center">
        <h1 className="font-weight-bold">WHY DOES A STUDENT ATHLETE</h1>
        <h1 className="font-weight-bold">NEED A SHORT INTERNSHIP?</h1>
        {/* For SEO only */}
        <p className="d-none">
          UNKLE501 | Donations and Philanthropy in the US
        </p>
        <h1 className="d-none">
          UNKLE501 | Donations and Philanthropy in the US
        </h1>
        <h2 className="d-none">
          UNKLE501 | Donations and Philanthropy in the US
        </h2>
        <h3 className="d-none">
          UNKLE501 | Donations and Philanthropy in the US
        </h3>
        <h4 className="d-none">
          UNKLE501 | Donations and Philanthropy in the US
        </h4>
        <h5 className="d-none">
          UNKLE501 | Donations and Philanthropy in the US
        </h5>
        <h6 className="d-none">
          UNKLE501 | Donations and Philanthropy in the US
        </h6>
        <a href="https://unkle501.com/" className="d-none">
          UNKLE501 | Donations and Philanthropy in the US
        </a>
        {/*  */}
        <Row className="row-reverse">
          <Col lg={4} md={4} sm={4} className="order-2 order-md-1 ">
            {/* className='order-3 order-md-3' */}
            {/* <div className='d-sm-flex d-md-col flex-wrap'> */}
            <div className="d-flex flex-md-column justify-content-between">
              <img
                src={BoyLogo}
                alt="BoyLogo"
                className="w-75 order-2 order-md-1 boy-img"
              />

              <img
                src={GirlLogo}
                alt="GirlLogo"
                className="w-75 order-1 order-md-2 girl-img"
              />
            </div>
          </Col>
          <Col
            lg={4}
            md={4}
            sm={12}
            className="mt-3 mt-md-0 order-1 order-md-2"
          >
            <img src={Because} alt="Because" className="img-fluid " />
            <img src={GroupImg} alt="GroupImg" className="img-fluid " />
            <div className="d-flex justify-content-between">
              <div className="d-block mt-3">
                <div className="d-flex">
                  <p className="font-weight-bold">
                    Here's <br /> Why
                  </p>
                  <img src={ArrowLeft} alt="ArrowLeft" className="w-25 ml-1" />
                </div>
                <Button
                  color="warning"
                  className="px-5 overflow-hidden"
                  onClick={handleClickHereIsWhy}
                >
                  <i className="fas fa-play" onClick={handleClickHereIsWhy}></i>
                </Button>
              </div>
              <div className="d-block mt-3 ">
                <div className="d-flex justify-content-center">
                  <img src={ArrowRight} alt="ArrowLeft" className="w-25 " />
                  <p className="font-weight-bold mr-0">
                    Here's <br /> How{" "}
                  </p>
                </div>
                <Button
                  color="warning"
                  className="px-5 overflow-hidden"
                  onClick={handleClickHereIsHow}
                >
                  <i className="fas fa-play" onClick={handleClickHereIsHow}></i>
                </Button>
              </div>
            </div>
          <h3 className="mt-5 font-weight-bold">Please donate! The <br /> athletes will thank you! </h3>
          </Col>
          <Col
            lg={4}
            md={4}
            sm={6}
            className="p-0 d-none d-md-block order-3"
            // order-2 className='order-1 order-md-0'
          >
            <div className="iframeForm d-none d-md-block">
              <iframe
                src="https://donorbox.org/embed/test-campaign-645"
                name="donorbox"
                allowpaymentrequest="allowpaymentrequest"
                seamless="seamless"
                frameborder="0"
                scrolling="no"
                height="900px"
                width="100%"
                style={{
                  maxWidth: "500px",
                  minWidth: "250px",
                  maxHeight: "none",
                }}
              ></iframe>
              <h3 className="font-weight-bold text-left">
                Please give what you can, thanks!
              </h3>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default NewHome;
