import { Link } from 'react-router-dom';
import UnkleDonate from '../components/UnkleDonate';
import NewHome from '../components/NewHome';

const Home = props => {
	return (
		<>
			{/* <UnkleDonate /> */}
			<NewHome />
		</>
	);
};

export default Home;
