import AuthView from '../views/auth/AuthView';
import Donation from '../views/Donation';
import Home from '../views/Home';
import Payment from '../views/Payment';
import StripePayment from '../views/StripePayment';
import NewHome from '../views/Home';
import UnkleDonate from '../components/UnkleDonate';

let routes = [
	{
		path: '/auth',
		component: AuthView,
		layout: 'auth',
	},
	{
		path: '/',
		component: NewHome,
		layout: 'main',
	},
	{
		path: '/Payment',
		component: Payment,
		layout: 'main',
	},
	{
		path: '/StripePayment',
		component: StripePayment,
		layout: 'main',
	},
];
export default routes;
