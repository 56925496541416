import React from "react";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CLIENT_KEY, BASE_DOMAIN } from "../constants/index";
import {
  Label,
  Button,
  Container,
  Row,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
let stripePromise = loadStripe(CLIENT_KEY);

function StripePayment() {
  let auth = useSelector((state) => state.auth);
  const history = useHistory();
  console.log(history);
  const [price, setPrice] = useState("");
  const [checkOut, setCheckOut] = useState(false);
  const [stripeCheckOut, setStripeCheckOut] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setPrice(history.location.state);
  }, []);
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="card p-3 col-md-6 m-auto">
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({ elements, stripe }) => (
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    await setLoading(true);
                    const cardElement = await elements.getElement(
                      CardNumberElement
                    );
                    const cardElement2 = await elements.getElement(
                      CardExpiryElement
                    );
                    const cardElement3 = await elements.getElement(
                      CardCvcElement
                    );

                    const { error, paymentMethod } =
                      await stripe.createPaymentMethod({
                        type: "card",
                        card: cardElement,
                        card: cardElement2,
                        card: cardElement3,
                      });
                    if (error) {
                      await setLoading(false);
                      alert("Card Details not Added");
                    } else {
                      // console.log("[payment Method]", paymentMethod)
                      const result = await stripe.createToken(cardElement);
                      // console.log("result", result)
                      if (result.error) {
                        alert(result.error.message);
                        // console.log("error result", result)
                        return;
                      }
                      let body = {
                        token: result.token.id,
                        amount: Number(price) * 100,
                        email: auth.user.email,
                      };
                      // console.log("body", body)
                      // await axios.post(`${BASE_DOMAIN}/fhpChargeUser`, body).then(
                      //   async (res) => {

                      //     if (res.data.error == false) {
                      //       let obj = {
                      //         athlete_id: props.match.params.id,
                      //         fan_id: auth.uid,
                      //         customer_id: res.data.customer.id,
                      //         charge_id: res.data.charge.id,
                      //         created_at: firebase.firestore.Timestamp.now(),
                      //         amount: {
                      //           currency_code: res.data.charge.currency,
                      //           value: price,
                      //         },
                      //         downloads: 1,
                      //         via: "card",
                      //       };
                      //       dispatch(licensePurchase(obj));
                      //       await setLoading(false);
                      //     } else {
                      //       toast.warning(res.data.msgs);
                      //       setLoading(false);
                      //     }
                      //   },
                      //   (error) => {
                      //     toast.warning(error);
                      //     setLoading(false);
                      //   }
                      // );
                    }
                  }}
                >
                  <Label class="offerheading text-center mb-2">
                    <h3 class="text-center">
                      <b>Card Detail</b>
                    </h3>
                  </Label>
                  <div className="row d-flex justify-content-between">
                    <Col md={6}>
                      <FormGroup>
                        <input
                          required
                          placeholder="Enter firstname..."
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <input
                          required
                          placeholder="Enter lastname..."
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <input
                          required
                          placeholder="Price "
                          className="form-control"
                          value={price}
                        />
                      </FormGroup>
                    </Col>
                  </div>

                  <FormGroup>
                    <Label>Card number</Label>
                    <div
                      className="form-control mt-2 d-flex"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <i className="fa fa-credit-card"></i>
                      <div style={{ flexBasis: "90%" }}>
                        <CardNumberElement
                          required
                          options={{
                            placeholder: "1234 1234 1234 1234",
                            style: {
                              base: {
                                // backgroundColor: "#232733",
                                fontSize: "16px",
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </FormGroup>
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup>
                        <Label>Expiry Date</Label>
                        <div
                          className="form-control mt-2 d-flex"
                          style={{
                            justifyContent: "space-between",
                          }}
                        >
                          <i className="fa fa-calendar"></i>
                          <div style={{ flexBasis: "90%" }}>
                            <CardExpiryElement
                              required
                              options={{
                                placeholder: "MM/YY",
                                style: {
                                  base: {
                                    fontSize: "16px",
                                  },
                                  invalid: {
                                    color: "#9e2146",
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup>
                        <Label>CVC/CVV</Label>
                        <div
                          className="form-control mt-2 d-flex"
                          style={{
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ flexBasis: "80%" }}>
                            <CardCvcElement
                              required
                              options={{
                                placeholder: "...",
                                style: {
                                  base: {
                                    fontSize: "16px",
                                  },
                                  invalid: {
                                    color: "#9e2146",
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                  <Button
                    className="auth-button mt-2 btn btn-lg btn-block"
                    type="submit"
                  >
                    {loading ? <Spinner size="md" /> : "Pay"}
                  </Button>
                </Form>
              )}
            </ElementsConsumer>
          </Elements>
        </div>
      </div>
    </div>
  );
}

export default StripePayment;
